<template>
  <div class="section is-main-section">
    <form v-on:submit.prevent="save" class="column max" id="user">
      <b-field label="ID" v-if="user.id" horizontal>{{ user.id }}</b-field>

      <b-field label="Created at" v-if="user.created_at" horizontal>{{
        user.created_at | moment('YYYY.MM.DD H:mm:ss')
      }}</b-field>

      <b-field label="Updated at" v-if="user.updated_at" horizontal>
        {{ user.updated_at | moment('YYYY.MM.DD H:mm:ss') }}
      </b-field>

      <b-field label="Last logged" horizontal>
        <template v-if="user.logged_at">
          {{ user.logged_at | moment('YYYY.MM.DD H:mm:ss') }}
        </template>
        <template v-else> --- </template>
      </b-field>

      <b-field label="Name" horizontal>
        <b-input v-model="user.name" required type="text"></b-input>
      </b-field>

      <b-field label="Admin" horizontal>
        <b-checkbox v-model="user.is_admin">{{ user.is_admin ? 'Yes' : 'No' }}</b-checkbox>
      </b-field>

      <b-field label="Email" horizontal>
        <b-input v-model="user.email" required type="email"></b-input>
      </b-field>

      <b-field label="Login" horizontal>
        <b-input v-model="user.login" required type="text" title="Must not contain spaces at the end or beginning">
        </b-input>
      </b-field>

      <b-field label="Password" horizontal>
        <b-input v-model="user.password" type="password"></b-input>
      </b-field>

      <b-field label="API token" horizontal class="token">
        <ApiToken :value="user.api_token"></ApiToken>
      </b-field>

      <b-field label="Note" horizontal>
        <b-input type="textarea" v-model="user.note" rows="2"></b-input>
      </b-field>

      <b-field label="Organizations" horizontal>
        <b-table :data="organizations" striped hoverable bordered :row-class="tableOrganizationsRowClass">
          <b-table-column field="id" label="Id" sortable v-slot="props">
            <router-link v-if="props.row.id" :to="{ name: 'organization', params: { id: props.row.id } }">
              {{ props.row.id }}
            </router-link>
          </b-table-column>

          <b-table-column field="name" label="Name" sortable v-slot="props">
            <template v-if="props.row.isNew">
              <OrganizationSelect v-model="props.row.id" :dennyIds="organizations.map((o) => o.id)" />
            </template>
            <template v-else>
              {{ props.row.name }}
            </template>
          </b-table-column>

          <b-table-column field="role" label="Role" v-slot="props">
            <b-select placeholder="Select role" v-model="props.row.role" size="is-small">
              <option value="editor">Editor</option>
              <option value="viewer">Viewer</option>
            </b-select>
          </b-table-column>

          <b-table-column field="role" label="Email Report" v-slot="props">
            <div class="field" style="padding-top: 4px">
              <b-checkbox v-model="props.row.email_report"></b-checkbox>
            </div>
          </b-table-column>

          <b-table-column field="remove" label="Remove" v-slot="props">
            <a v-if="props.row.isNew" v-on:click="removeOrganization(props.row)">
              <b-icon title="Remove" icon="minus-circle" size="is-mediu" type="is-danger"></b-icon>
            </a>
            <div v-else class="field" style="padding-top: 4px">
              <b-checkbox v-model="props.row.remove"></b-checkbox>
            </div>
          </b-table-column>

          <!-- <b-table-column field="action" label="" v-slot="props">

          </b-table-column> -->

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>Nothing here.</p>
              </div>
            </section>
          </template>

          <template slot="footer">
            <b-button @click="addOrganization" type="is-info" size="is-small">
              <i class="mdi mdi-plus-circle" title="Add"></i> Add
            </b-button>
          </template>
        </b-table>
      </b-field>

      <b-field label horizontal>
        <div class="buttons">
          <b-button v-if="!user.id" type="is-primary" icon-left="plus-box" native-type="submit">Add User</b-button>
          <b-button v-if="user.id" :disabled="!isChange" type="is-success" icon-left="content-save" native-type="submit"
            >Save User</b-button
          >
          <b-button v-if="user.id" type="is-danger" v-on:click="showDeleteDialog" icon-left="trash-can"
            >Delete User</b-button
          >
          <b-button v-if="user.id" type="is-link" v-on:click="showRenewTokenDialog" icon-left="refresh"
            >Renew User Token</b-button
          >
        </div>
      </b-field>
    </form>

    <div class="modal is-active" v-if="deleteDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeDeleteDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to delete this user?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="deleteItem">Delete</button>
          <button class="button" v-on:click="closeDeleteDialog">Cancel</button>
        </footer>
      </div>
    </div>

    <div class="modal is-active" v-if="renewTokenDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Renew token confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeRenewTokenDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to renew API token for this user?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="renewToken">Renew token</button>
          <button class="button" v-on:click="closeRenewTokenDialog">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { isEqual, cloneDeep } from 'lodash';
import ApiToken from '@/components/ApiToken.vue';
import User from '../models/User';
import OrganizationSelect from '../components/OrganizationSelect.vue';

export default {
  name: 'User',
  components: { ApiToken, OrganizationSelect },
  data() {
    return {
      user: {},
      userOrig: {},
      deleteDialog: false,
      organizations: [],
      organizationsOrig: [],
      renewTokenDialog: false,
    };
  },
  async created() {
    await this.fetch();
    await this.fetchUserOrganizations();
  },
  computed: {
    isChangeUserData() {
      return !isEqual(this.user, this.userOrig);
    },
    isOrganizationChange() {
      return !isEqual(this.organizations, this.organizationsOrig);
    },
    isChange() {
      return this.isChangeUserData || this.isOrganizationChange;
    },
  },
  methods: {
    updateUser(user) {
      this.user = user;
      this.userOrig = cloneDeep(user);
    },
    updateOrganizations(organizations) {
      this.organizationsOrig = cloneDeep(organizations);
      this.organizations = organizations;
    },
    async fetch() {
      if (!this.$route.params.id) {
        this.updateUser({});
        return;
      }
      const { data } = await User.getOne(this.$route.params.id);
      this.updateUser(data);
    },
    async fetchUserOrganizations() {
      if (!this.user.id) {
        this.organizations = [];
        return;
      }
      const { data } = await this.$http.get(`/v1/user/${this.user.id}/organizations`);
      this.updateOrganizations(data);
    },
    async save() {
      this.user.name = this.user.name.trim();
      this.user.login = this.user.login.trim();
      this.user.email = this.user.email.trim();

      let redirect = false;
      let toast = 'User was saved';

      if (this.isChangeUserData) {
        if (this.user.id) {
          const { data } = await User.update(this.user);
          this.updateUser(data);
        } else {
          const { data } = await User.add(this.user);
          this.updateUser(data);
          toast = 'User was created';
          redirect = true;
        }
      }

      if (this.isOrganizationChange) {
        const requests = [];
        for (let index = 0; index < this.organizations.length; index += 1) {
          const organization = this.organizations[index];
          if (organization.remove) {
            requests.push(this.$http.delete(`/v1/user/${this.user.id}/organization/${organization.id}`));
          } else if (organization.isNew && organization.id) {
            requests.push(
              this.$http.post(`/v1/user/${this.user.id}/organization`, {
                organization_id: organization.id,
                role: organization.role,
                email_report: organization.email_report,
              })
            );
          } else {
            const orig = this.organizationsOrig[index];
            if (orig && (organization.role !== orig.role || organization.email_report !== orig.email_report)) {
              requests.push(
                this.$http.put(`/v1/user/${this.user.id}/organization/${organization.id}`, {
                  role: organization.role,
                  email_report: organization.email_report,
                })
              );
            }
          }
        }
        await Promise.all(requests);
        await this.fetchUserOrganizations();
      }

      this.$toast.success(toast);

      if (redirect) {
        this.$router.push({ name: 'user', params: { id: this.user.id } });
      }
    },
    async deleteItem() {
      if (!this.deleteDialog) return;
      await User.delete(this.user.id);
      this.fetch();
      this.closeDeleteDialog();
      this.$toast.success('User was deleted');
      this.$router.push({ name: 'users' });
    },
    showDeleteDialog(e) {
      e.preventDefault();
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = null;
    },
    showRenewTokenDialog(e) {
      e.preventDefault();
      this.renewTokenDialog = true;
    },
    closeRenewTokenDialog() {
      this.renewTokenDialog = false;
    },
    async renewToken() {
      if (!this.renewTokenDialog) return;
      const { data } = await User.update({
        id: this.user.id,
        api_token: 'renew',
      });
      this.$set(this.user, 'api_token', data.api_token);
      this.$set(this.user, 'updated_at', data.updated_at);
      this.closeRenewTokenDialog();
      this.$toast.success('User token was renewed');
    },
    addOrganization() {
      this.organizations.push({
        isNew: true,
        id: null,
        name: '',
        role: 'editor',
        email_report: false,
      });
    },
    removeOrganization(row) {
      const index = this.organizations.indexOf(row);
      if (row.isNew && index !== -1) {
        this.organizations.splice(index, 1);
      }
    },
    tableOrganizationsRowClass(organization, index) {
      if (organization.isNew) return 'is-new';
      if (organization.remove) return 'remove';
      const orig = this.organizationsOrig[index];
      if (orig && (organization.role !== orig.role || organization.email_report !== orig.email_report)) return 'change';
      return '';
    },
  },
};
</script>
<style>
/* #user .table {
  background-color: transparent !important;
} */

#user .table .is-new {
  background-color: #48c78e;
}
#user .table .remove {
  background-color: #f14668;
  color: #fff;
}
#user .table .change {
  background-color: #ffe08a;
}
</style>
