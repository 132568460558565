import Model from './index';

export default {
  getAll(sortField, sortOrder, limit, offset) {
    return Model.get('/v1/users', {
      params: {
        sort_field: sortField,
        sort_order: sortOrder,
        limit,
        offset,
      },
    });
  },
  getOne(id) {
    return Model.get(`/v1/user/${id}`);
  },
  add(obj) {
    return Model.post('/v1/users', obj);
  },
  update(obj) {
    return Model.put(`/v1/user/${obj.id}`, obj);
  },
  delete(id) {
    return Model.delete(`/v1/user/${id}`);
  },
  getOrganizations(id) {
    return Model.get(`/v1/user/${id}/organizations`);
  },
  addOrganization(id, obj) {
    return Model.post(`/v1/user/${id}/organization`, obj);
  },
  deleteOrganization(id, orgId) {
    return Model.delete(`/v1/user/${id}/organization/${orgId}`);
  },
};
